import { Environment } from '../environment/environment';
import { ActionSetEpistemicModel } from '../environment/action-set-epistemic-model';
import { Valuation } from '../epistemicmodel/valuation';
import { ExampleDescription } from '../environment/exampledescription';
import { SimpleWorld } from './simple';
import { ExplicitEpistemicModel } from '../epistemicmodel/explicit-epistemic-model';
import { World } from '../epistemicmodel/world';

export class CommonknowledgeStrictlyMoreExpressive extends ExampleDescription {
    readonly d = 4;

    getDescription(): string[] {
        return [
            'This example shows that Epistemic logic with common knowledge is strictly more expressive than Epistemic logic without common knowledge. In particular, it shows that it is not possible to express common knowledge with a formula of modal depth 3. Indeed, consider both models generated by executing one of the actions. Any formula of modal depth 3 cannot distinguish both models but formula CK p can.'
        ];
    }

    getAtomicPropositions(): string[] {
        return ['p'];
    }

    getName() {
        return 'Language with Common Knowledge is more expressive';
    }

    getModel(lastPTrue: boolean) {
        let M = new ExplicitEpistemicModel();

        for (let i = 0; i < this.d; i++) {
            M.addWorld('w' + i, new SimpleWorld(new Valuation(['p'])));
        }

        if (lastPTrue) {
            M.addWorld('w' + this.d, new SimpleWorld(new Valuation(['p'])));
        } else {
            M.addWorld('w' + this.d, new SimpleWorld(new Valuation([])));
        }

        M.makeReflexiveRelation('a');
        M.makeReflexiveRelation('b');
        for (let i = 0; i < this.d; i++) {
            M.addEdge(i % 2 == 0 ? 'a' : 'b', 'w' + i.toString(), 'w' + (i + 1).toString());
        }
        M.makeSymmetricRelation('a');
        M.makeSymmetricRelation('b');

        M.setPointedWorld('w0');
        return M;
    }

    getInitialEpistemicModel(): import('../epistemicmodel/epistemic-model').EpistemicModel {
        return this.getModel(true);
    }

    getActions() {
        return [
            new ActionSetEpistemicModel({
                name: 'Epistemic state of depth ' + this.d + ' with CK p',
                epistemicModel: this.getModel(true)
            }),
            new ActionSetEpistemicModel({
                name: 'Epistemic state with p false at modal depth ' + this.d,
                epistemicModel: this.getModel(false)
            })
        ];
    }

    getWorldExample(): World {
        return new SimpleWorld(new Valuation(['p']));
    }

    onRealWorldClick(env: Environment, point: any): void {}
}
